import React from "react";
import StorageGateway from "lib/storage-gateway";

const NewDashboard = (props) => {
  // Fetch tokens and company ID from StorageGateway
  const oAuthToken = StorageGateway.get("oAuthToken");
  const company_id = StorageGateway.get("nre_company_id");
  const authToken = StorageGateway.get("graniteToken");
  const userId = StorageGateway.get("userId");

  return (
    <div
      style={{
        height: "calc((90vh - 102px) - 65px)",
        marginRight: "-15px",
      }}
    >
      <iframe
        id="nre"
        title="nre-iframe"
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ width: "100%", height: "100%" }}
        src={`${process.env.REACT_APP_WMAPPER_BASE_URL}/konect-dashboard?access_token=${authToken}&oAuthToken=${oAuthToken}&company_id=${company_id}&user_id=${userId}`}
      />
    </div>
  );
};

export default NewDashboard;
