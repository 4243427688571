import React, { useState } from "react";
import "./ToggleButton.css"; // Create a CSS file for the styles

const ToggleButton = ({ updateState, newDashboard, isLoading }) => {
  const handleToggle = (isOld) => {
    updateState("newDashboard", isOld);
  };

  return (
    <div
      className={
        isLoading
          ? "toggle-container-dashboard-disable"
          : "toggle-container-dashboard"
      }
    >
      <button
        className={`toggle-button-dashboard ${newDashboard ? "active" : ""}`}
        onClick={() => handleToggle(true)}
      >
        New Dashboard
      </button>
      <button
        className={`toggle-button-dashboard ${!newDashboard ? "active" : ""}`}
        onClick={() => handleToggle(false)}
      >
        Old Dashboard
      </button>
    </div>
  );
};

export default ToggleButton;
